<template>
  <div class="provider-line-page pt-10 text-center">
    <v-row class="qrcodeArea">
      <v-col cols="12" class="text-center pt-10">
        <div class="mb-6 title">商品QR Code:</div>
        <qr-code-vue :value="lineLink" :size="320" :margin="40" />
      </v-col>
      <v-col cols="12" class="text-center">
        <v-btn color="primary" @click="download">
          <v-icon size="14" left>fa fa-download</v-icon>
          {{ "action.download" | t }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="linkArea inputArea mt-10">
      <v-col class="col-1 col-md-3"></v-col>
      <v-col class="text-center col-10 col-md-6">
        <div class="title mb-4">商品連結</div>
        <div class="d-flex align-center">
          <v-text-field
            :value="lineLink"
            class="d-inline-block"
            outlined
            dense
            disabled
            hide-details
          ></v-text-field>
          <v-btn color="primary" @click="copy('link')">
            <v-icon size="14">fa fa-copy</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row class="idArea mt-10">
      <v-col cols="12">
        <v-btn color="primary" @click="share()">
          分享連結
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import qrCodeVue from "qrcode.vue";

import { mapGetters } from "vuex";

export default {
  components: {
    qrCodeVue,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters("base", ["layoutProviderLineAtId"]),
    // QRCodeSRC() {
    //   return `https://qr-official.line.me/gs/M_${this.layoutProviderLineAtId}_GW.png`;
    // },
    lineLink() {
      return `${window.location.origin}/provider-store/${this.providerId}/product/${this.product.id}?addToCart=true`
    },
    providerName() {
      return this.$store.getters["member/providerName"];
    },
    providerId() {
      return this.$store.getters['member/providerId']
    },
    product() {
      return this.$route.query;
    },
  },
  methods: {
    async share() {
      if (navigator.share) {
        // 1718595090311972/product/1718692583164796
        const shareData = {
          url: this.lineLink,
          title: this.product.id,
          text: this.product.name,
        };
        try {
          await navigator.share(shareData);
        } catch (error) {
          const { name, message } = error;
          if (name === "AbortError") {
            console.log("您已取消分享此訊息");
          } else {
            console.log("發生錯誤", error);
          }
        }
      }
    },
    download() {
      let canvas = document
        .getElementById("app")
        .getElementsByTagName("canvas");

      let a = document.createElement("a");

      a.href = canvas[0].toDataURL("img/png");
      a.download = `${this.providerName}_${this.product.name}_qrcode`;
      a.click();
    },
    copy(key) {
      const copyText =
        key == "link" ? this.lineLink : this.layoutProviderLineAtId;
      navigator.clipboard.writeText(copyText).then(() => {
        this.$snotify.success(null, `已成功複製!`);
      });
    },
  },
};
</script>

<style lang="sass">
.provider-line-page
  .inputArea
      input
          text-align: center !important
          color: rgba(0, 0, 0, 0.87) !important
      fieldset
          border-top-right-radius: initial !important
          border-bottom-right-radius: initial !important

      button
          border-top-left-radius: initial !important
          border-bottom-left-radius: initial !important
          height: 40px !important
</style>
